<template >
  <div id="blog">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="blog-post-cont">
      <img
      :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image"
        alt="header"
        class="header-blog-cont"
      />
      <img
      :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv"
        alt="header"
        class="header-blog-cont-mb"
      />
      <BlogCont  :style="{ display: displayBlog[$route.path] }"/>
      <BlogContExpansion  :style="{ display: displayBlogExp[$route.path] }"/>
      <BlogContFuerzasBasicas  :style="{ display: displayBlogFBas[$route.path] }"/>
      <BlogContEventos  :style="{ display: displayBlogEv[$route.path] }"/>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import BlogCont from "../components/BlogCont";
import BlogContExpansion from "../components/BlogContExpansion";
import BlogContFuerzasBasicas from "../components/BlogContFuerzasBasicas";
import BlogContEventos from "../components/BlogContEventos";
import { mapState, mapActions, mapMutations } from "vuex"; 
let urlpath = require('../global/url');

export default {
  data() {
    return {
      urlpath,
      backgr: [],
      displayBlog: {
        '/blog': 'block!important',
        '/blog/expansion': 'none!important',
        '/blog/fuerzas-basicas': 'none!important',
        '/blog/eventos': 'none!important',
      },
      displayBlogExp: {
        '/blog': 'none!important',
        '/blog/expansion': 'block!important',
        '/blog/fuerzas-basicas': 'none!important',
        '/blog/eventos': 'none!important',
      },
      displayBlogFBas: {
        '/blog': 'none!important',
        '/blog/expansion':'none!important',
        '/blog/fuerzas-basicas': 'block!important',
        '/blog/eventos': 'none!important',
      },
      displayBlogEv: {
        '/blog': 'none!important',
        '/blog/expansion':'none!important',
        '/blog/fuerzas-basicas': 'none!important',
        '/blog/eventos': 'block!important',
      },
    }
  },
  components: {
    HeaderHome,
    BlogCont,
    BlogContExpansion,
    BlogContFuerzasBasicas,
    BlogContEventos,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  async created() {
      let payload2 = {
        id: 'Noticias',
        option: "cabeceras/section",
      };
      this.backgr = await this.getCabecerasById(payload2);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("cabeceras", ["getCabecerasById"]), },
};
</script>

<style scoped>
.blog-post-cont {
    background: white;
}.header-blog-cont {
  display: flex;
    width: 100%;
    height: 29.896vw;
}
.header-blog-cont-mb {
  display: none;
}.blog-post-cont .blog-nt-container {
    padding: 5vw 8.835vw;
}
@media (max-width: 768px) {
  .blog-post-cont {
    padding-top: 23.8vw;
}.header-blog-cont {
    display: none;
  }
  .header-blog-cont-mb {
    display: flex;
    width: auto;
    height: 30.435vw;
  }
}
</style>