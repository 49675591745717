<template >
    <div class="blog-nt-container">
    <div class="titulo-u-noticias">
      <h4><span>EVENTOS</span></h4>
      <!--<div class="patro-u-n">
                <p>PATROCINADOR OFICIAL</p>
                <img src="../assets/tkt.png" alt="sponsor">
            </div>-->
        <!--<Sponsor :secSponsor="'NOTICIAS'"/>-->
    </div>

    <div class="last-cont">
      <div class="l-cont pc" v-for="lnw in blogdatatouille" :key="lnw.id">
          <a :href="'/blog/titulo/' + lnw.url">
        <div class="l-div">
            <div class="hover-diag  "></div>
            <div
            :style="{ backgroundImage: 'url(' + url + '/get-blog-image/' + lnw.image + ')'}"
              width="513"
              height="360"
              alt="e"
              class="l-m-content"
            />
            <img
              src="../assets/red-rec.png"
              width="118"
              height="12"
              alt="rr"
              class="red-rec"
            />
        </div>
          </a>
        <div class="text-cont">
          <h6 class="date">{{ dataSortDue(lnw.date) }}</h6>
          <a :href="'/blog/titulo/' + lnw.url">
            <h4 class="titulo">{{ lnw.title }}</h4>
            <p class="parrafo">{{ splitHTML(lnw.content) }}</p>
          </a>
        </div>
        <!--<p class="texto-prev" v-html="lnw.content"></p>-->
      </div>

      <div class="l-cont mo" v-for="lnw in blogdatatouille" :key="lnw.id">
          <a :href="'/blog/titulo/' + lnw.url">
        <div class="l-div">
            <img
              :src="url + '/get-blog-image/' + lnw.image"
              width="513"
              height="360"
              alt="e"
              class="l-m-content"
            />
            <img
              src="../assets/red-rec.png"
              width="118"
              height="12"
              alt="rr"
              class="red-rec"
            />
        </div>
          </a>
        <div class="text-cont">
          <h6 class="date">{{ dataSortDue(lnw.date) }}</h6>
          <a :href="'/blog/titulo/' + lnw.url">
            <h4 class="titulo">{{ lnw.title }}</h4>
            <p class="parrafo">{{ splitHTML(lnw.content) }}</p>
          </a>
          <!--<p class="texto-prev" v-html="lnw.content"></p>-->
        </div>
      </div>
    </div>
        
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Sponsor from "../components/widgets/SponsorSecW";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  data() {
    return {};
  },
  components: {
    Sponsor,
  },
  created() {
    this.findByCategory3();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    blogdata() { 
      return this.$store.getters["blog/data"];
    },
    blogdatatouille() {
      let match = this.$store.getters["blog/data6"];
      let elekipo = match.sort(
        (a, b) => {return new Date(b.date) - new Date(a.date);}
      );
      return elekipo;    },
  },
  methods: {
    ...mapActions("blog", ["findByCategory3"]),
    ...mapActions("blog", ["getImageFile"]),

    dataTransform: function(date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function(date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },

    splitHTML(texto){
      let regex = /(<([^>]+)>)/ig
      let textoS = texto.replace(regex, "");
      let maxLength = 150
      let trimmedString = textoS.substr(0, maxLength);
       trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
      return trimmedString + " ...";
    },

    newsdateago: function(date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD")
        .locale(es)
        .fromNow(true);
      return newsdata;
    },

    reverseArr(input) {
    var ret = new Array;
    for(var i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
},


    findAll: async function() {
      let result = await this.$store.dispatch("blog/findAll", {
        option: "blog",
      });
      this.info = result;
      return result;
    },

    findByCategory3: async function() {
      let result = await this.$store.dispatch("blog/findByCategory3", {
        option: "blog/category", "id": 14
      });
      this.info = result;
      return result;
    },

    filterTitle: function(title) {
      let result = title.replaceAll(" ", "-").replaceAll('/','_');
      return result;
    },
  },
};
</script>

<style scoped>
.l-m-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    background-size: 115%!important;
    background-position-x: center!important;
    filter: contrast(1);
    transition: 0.5s;
}.l-cont:hover .l-m-content {
    background-position-x: center!important;
    background-size: 150%!important;
}
</style>
